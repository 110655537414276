import { connect } from 'react-redux';

import UserRosterUpload from './UserRosterUpload';

import { clearRosterManagement, receiveUserRosterUploadInitialState, createUserRosterUpload } from '../../../../Actions/RosterManagementActions';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  progressData: state.admin.rosterManagement || {},
});

const mapDispatchToProps = (dispatch) => ({
  clearRosterManagement: () => dispatch(clearRosterManagement()),
  receiveUserRosterUploadInitialState: (payload) => dispatch(receiveUserRosterUploadInitialState(payload)),
  createUserRosterUpload: (userRosterUpload) => dispatch(createUserRosterUpload(userRosterUpload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRosterUpload);
