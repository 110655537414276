import React from 'react';
import { Switch } from 'react-router-dom';

import './CanopyB2BAdminDashboard.scss';
import { B2bAdminRoute } from '../../../Util/RouteUtil';
import AdminHome from './AdminHome';
import B2BAdminSideBar from '../../SideBars/B2BAdminSideBar/B2BAdminSideBar';
import AddSearchUsers from './AddSearchUsers/AddSearchUsers';
import CourseProgress from './CourseProgress';
import ManageGroups from './ManageGroups';
import GroupDetails from './GroupDetails';
import UserDetailContainer from './AddSearchUsers/UserDetail/UserDetailContainer';
import Profile from '../CanopyUserDashboard/Profile/Profile';
import Learn from './Learn/Learn';
import Speak from './Speak/Speak';
import Compliance from './Compliance/Compliance';
import Credential from './Credential/Credential';
import InviteUsersNew from '../../Forms/InviteUsers/InviteUsersContainer';
import InviteUsersDetail from './InviteUsers/InviteUsers';
import BatchProfileUploadContainer from './BatchProfileUpload/BatchProfileUploadContainer';
import UserRosterUploadContainer from './UserRosterUpload/UserRosterUploadContainer';
import NotificationPageContainer from '../../Pages/UserNotifications/NotificationPage/NotificationPageContainer';
import InstitutionResourceManagementContainer from '../../Pages/InstitutionResources/Management/ManagementContainer';
import AdminHomeDashboard from './AdminHomeDashboard/AdminHomeDashboard';

const CanopyB2BAdminDashboard = ({ currentUser, updateAccount, updatePhoto, updatePassword }) => {
    return (
        <div className="B2B-Admin-Dashboard">
            <B2bAdminRoute
                path="/account/:path"
                component={B2BAdminSideBar}
            />
            <div className="B2B-Admin-Dashboard-Main">
                <Switch>
                    <B2bAdminRoute
                        exact
                        path="/account/groups"
                        component={ManageGroups}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/groups/:groupId"
                        component={GroupDetails}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/progress"
                        component={CourseProgress}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/search"
                        component={AddSearchUsers}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/search/:id"
                        component={UserDetailContainer}
                    />

                    <B2bAdminRoute
                        path="/account/learn"
                        component={() => <AdminHome currentUser={currentUser} />}
                    />

                    <B2bAdminRoute
                        path="/account/dashboard/compliance"
                        component={Compliance}
                    />

                    <B2bAdminRoute
                        path="/account/dashboard/learn"
                        component={Learn}
                    />

                    <B2bAdminRoute
                        path="/account/dashboard/credential"
                        component={Credential}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/dashboard/speak"
                        component={Speak}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/dashboard/home"
                        component={AdminHomeDashboard}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/invite_users/new"
                        component={InviteUsersNew}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/invite_users/detail"
                        component={InviteUsersDetail}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/batch_profile_uploads/new"
                        component={BatchProfileUploadContainer}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/user_roster_uploads/new"
                        component={UserRosterUploadContainer}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/notifications"
                        component={NotificationPageContainer}
                    />

                    <B2bAdminRoute
                        exact
                        path="/account/resource_library"
                        component={InstitutionResourceManagementContainer}
                    />

                    <B2bAdminRoute
                        path="/account/profile"
                        component={
                            () => (
                                <div style={{ width: '100%' }}>
                                    <Profile
                                        currentUser={currentUser}
                                        updateAccount={updateAccount}
                                        updatePhoto={updatePhoto}
                                        updatePassword={updatePassword}
                                    />
                                </div>
                            )
                        }
                    />
                </Switch>
            </div>
        </div>
    );
};

export default CanopyB2BAdminDashboard;
